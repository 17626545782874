import React from "react"
import Image from "./image"
import { navigate } from 'gatsby-plugin-intl'
import { stripHtml } from "../utils"
import IconButton from "./icon-button"


const EditorialBox = ({ editorial, index, isInSearchMode }) => {

  const maxWords = 25
  const separator = ' '
  const words = stripHtml(editorial.body).split(separator)
  const editorialBodyPreview = words.length >= maxWords ? `${words.slice(0, maxWords).join(separator)}...` : words.join(separator)

  const isHighlight = index <= 1 && !isInSearchMode
  return (
    <div className={`c-editorialbox ${isHighlight ? 'c-editorialbox--highlight' : ''}`} onClick={() => navigate(`/editoriali/${editorial.slug}`)}>
      <div className="c-editorialbox__image">
        <div class="c-editorialbox__label">{editorial.category && editorial.category.name ? editorial.category.name.toUpperCase() : '-'}</div>
        <Image image={editorial.image_cover} />
      </div>
      <div className="c-editorialbox__content">
        <span class="c-editorialbox__content__title">{editorial.title}</span>
        <div class="c-editorialbox__content__inner">
          <p>{editorialBodyPreview}</p>
          {isHighlight && <IconButton icon="UilArrowRight" size="26px" class="a-btn--rounded" />}
        </div>
      </div>
    </div>
  )
}

export default EditorialBox
