import React, { useState } from "react"
import { UilAngleRight, UilAngleLeft } from "@iconscout/react-unicons"

const Tabs = ({ tabEntries, activeTab, onTabChange }) => {
  const menuTab = typeof window !== "undefined" && window.document.querySelector(".c-tabs__menu")
  const [scrollAmount, setScrollAmount] = useState(0)

  const navigate = direction => {
    direction === "next"
      ? (menuTab.scrollLeft += 100)
      : (menuTab.scrollLeft -= 100)

    setScrollAmount(menuTab.scrollLeft)
  }

  menuTab &&
    menuTab.addEventListener("scroll", () => {
      setScrollAmount(menuTab.scrollLeft)
    })

  return (
    <div className="c-tabs__container" id="tabs">
      <div className="a-container a-container--fluidMobile">
        <UilAngleLeft
          size={20}
          className={`c-tabs__icon__prev ${
            scrollAmount > 10 ? "is-active" : ""
          }`}
          onClick={() => navigate("prev")}
        />
        <UilAngleRight
          size={20}
          className={`c-tabs__icon__next ${
            scrollAmount < 100 ? "is-active" : ""
          }`}
          onClick={() => navigate("next")}
        />
        <div className="c-tabs__menu">
          {tabEntries.map((tabEntry, i) => {
            return (
              <div
                key={tabEntry.id}
                className={`c-tabs__item ${tabEntry.id === activeTab &&
                  "active"}`}
                onClick={() => {
                  onTabChange(tabEntry.id)
                }}
                onKeyDown={null}
                role="link"
                data-last={i + 1 === tabEntries.length ? true : null}
                data-first={i === 0 ? true : null}
              >
                {tabEntry.name}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Tabs
