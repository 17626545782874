// ./src/pages/blog.js
import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { groupBy } from "lodash"
import Helmet from 'react-helmet'

import { localize } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tabs from "../components/tabs"
import EditorialBox from "../components/editorial-box"
import TopBar from "../components/topbar"

// The query used to provide the page data
export const pageQuery = graphql`
  query GatsbyEditorialsPageQuery {
    allDirectusBlock(filter: { page: { eq: "editoriali" } }) {
      nodes {
        data {
          body
          title
          lang
        }
      }
    }
    allDirectusEditorial {
      nodes {
        category {
          data {
            name
            slug
            lang
          }
        }
        directusId
        id
        hidden
        image_cover {
          private_hash
          title
          localFile {
            childImageSharp {
              fixed(width: 1024) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          body
          title
          slug
          lang
        }
        main_product {
          id
          data {
            name
          }
        }
      }
    }
  }
`

const allTabSlug = "all"

const Editorials = ({ data, intl, location }) => {
  const {
    allDirectusEditorial: { nodes: editorials },
    allDirectusBlock: { nodes: blocks },
  } = data

  const localizedEditorials = editorials.map(editorialData => {
    const localized = localize(editorialData, intl.locale)
    localized.category = localize(localized.category, intl.locale)
    return localized
  })

  const localizedBlocks = blocks.map(blockData =>
    localize(blockData, intl.locale)
  )

  const editorialsByCat = groupBy(localizedEditorials, "category.slug")
  const slugs = Object.keys(editorialsByCat)

  const urlTab = location.hash.substr(1)
  const [activeTab, setActiveTab] = useState(urlTab || allTabSlug)
  const [searchValue, setSearchValue] = useState("")

  const tabEntries = slugs.map(slug => {
    return {
      id: slug,
      name: editorialsByCat[slug][0].category.name,
    }
  })

  tabEntries.unshift({ id: allTabSlug, name: "Tutti" })

  const onSearch = event => {
    setSearchValue(event.target.value)
  }

  let currentEditorials =
    activeTab === "all" ? localizedEditorials : editorialsByCat[activeTab]
  if (searchValue) {
    currentEditorials = localizedEditorials.filter(editorial =>
      editorial.title.toLowerCase().includes(searchValue.toLowerCase())
    )
  }

  currentEditorials = currentEditorials.filter(editorial => !editorial.hidden).reverse()

  useEffect(() => {
    if (searchValue) setActiveTab(allTabSlug)
  }, [searchValue])

  useEffect(() => {
    if (location.hash) setActiveTab(location.hash.replace("#", ""))
    else setActiveTab(tabEntries[0].id)
  }, [location, tabEntries])

  return (
    <Layout name="p-editorials">
      <Helmet
        bodyAttributes={{
          class: 'body-for-sticky',
        }}
      />
      <Seo title={localizedBlocks[0].title} />
      <TopBar title={localizedBlocks[0].title} small>
        <input
          type="text"
          name="search"
          className="p-editorials__search"
          placeholder={intl.formatMessage({ id: "terms.search" })}
          value={searchValue}
          onChange={onSearch}
        ></input>
      </TopBar>
      <Tabs
        tabEntries={tabEntries}
        activeTab={activeTab}
        onTabChange={slug => {
          setActiveTab(slug)
          typeof window !== "undefined" && navigate(`/editoriali#${slug}`)
        }}
      />
      <div className="a-container">
        <div className="p-editorials__container">
          {currentEditorials.map((editorial, index) => (
            <EditorialBox
              isInSearchMode={!!searchValue}
              key={editorial.id}
              index={index}
              editorial={editorial}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(Editorials)
